html, body {
    margin: 0;
    padding: 0
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, input, textarea, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: inherit
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

ol, ul {
    list-style: none
}

q:before, q:after, blockquote:before, blockquote:after {
    content: ""
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

a:focus {
    outline: 0
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block
}

audio, canvas, video {
    display: inline-block
}

audio:not([controls]) {
    display: none
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

nav, section, aside, article, figure, header, footer, hgroup {
    display: block;
    clear: both
}

a {
    text-decoration: none
}

nav ul, footer ul {
    list-style-type: none
}

html {
    font-size: 14px;
    line-height: 1
}

input::-webkit-input-placeholder {
    color: #c0c4c8
}

.clearfix {
    position: relative
}

.clearfix:after {
    display: table;
    content: ""
}

.clearfix:after {
    clear: both
}

.ib {
    display: inline-block;
    zoom: 1
}

.ib.middle {
    vertical-align: middle
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.hidden {
    display: none
}

.pointer {
    cursor: pointer
}

.caps {
    text-transform: uppercase
}

.right-align {
    text-align: right
}

.left-align {
    text-align: left
}

.center, .center-align {
    text-align: center
}

.vert-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.h-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH
}

body {
    -webkit-animation-duration: .1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: .1s
}

@-webkit-keyframes fontfix {
    from {
        opacity: 1
    }

    to {
        opacity: 1
    }
}

.no-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.break-word {
    word-break: break-word
}

.break-all {
    word-break: break-all
}

.no-wrap {
    white-space: nowrap
}

@-webkit-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.4;
    opacity: 0;
    filter: alpha(opacity=0)
}

.tooltip.fade {
    -webkit-animation: fadein .1s;
    animation: fadein .1s
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100)
}

.tooltip.top {
    margin-top: -4px;
    padding: 7px 0
}

.tooltip.right {
    margin-left: 4px;
    padding: 0 7px
}

.tooltip.bottom {
    margin-top: 4px;
    padding: 7px 0
}

.tooltip.left {
    margin-left: -4px;
    padding: 0 7px
}

.tooltip-inner {
    font-family: 'Open Sans', 'open_sans', sans-serif;
    max-width: 220px;
    padding: 8px 12px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #000;
    border-radius: 3px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -7px;
    border-width: 7px 7px 0;
    border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    left: 7px;
    border-width: 7px 7px 0;
    border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    right: 7px;
    border-width: 7px 7px 0;
    border-top-color: #000
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
    border-right-color: #000
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -7px;
    border-width: 7px 0 7px 7px;
    border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -7px;
    border-width: 0 7px 7px;
    border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    left: 7px;
    border-width: 0 7px 7px;
    border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    right: 7px;
    border-width: 0 7px 7px;
    border-bottom-color: #000
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    display: none;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    white-space: normal
}

.popover.top {
    margin-top: -10px
}

.popover.right {
    margin-left: 10px
}

.popover.bottom {
    margin-top: 10px
}

.popover.left {
    margin-left: -10px
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0
}

.popover-content {
    padding: 9px 14px
}

.popover .arrow, .popover .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover .arrow {
    border-width: 11px
}

.popover .arrow:after {
    border-width: 10px;
    content: ""
}

.popover.top .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px
}

.popover.top .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff
}

.popover.right .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25)
}

.popover.right .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff
}

.popover.bottom .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px
}

.popover.bottom .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff
}

.popover.left .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25)
}

.popover.left .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px
}

ul.list {
    color: #666;
    list-style: disc inside
}

ul.list li {
    line-height: 140%
}

ol.list {
    color: #666;
    list-style: decimal inside
}

ol.list li {
    line-height: 140%
}

body.modal-open {
    overflow: hidden
}

body.fixed-body {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important
}

.no-text-selection {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none
}

@-webkit-keyframes ui-fade-in-up {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ui-fade-in-up {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes ui-fade-in-down {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
}

@keyframes ui-fade-in-down {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
}

.easeUp {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-name: ui-fade-in-up;
    animation-name: ui-fade-in-up
}

.easeDown {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-name: ui-fade-in-down;
    animation-name: ui-fade-in-down
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0
}

.container .column, .container .columns {
    float: left;
    display: inline;
    margin-left: 1.04166666%;
    margin-right: 1.04166666%
}

.container .column.clear, .container .columns.clear {
    clear: both
}

.container .column.right, .container .columns.right {
    float: right
}

.container .column.no-float, .container .columns.no-float {
    display: inline-block;
    vertical-align: top;
    float: none;
    zoom: 1
}

.column.alpha, .columns.alpha {
    margin-left: 0
}

.column.omega, .columns.omega {
    margin-right: 0
}

.container .sixteen.columns.alpha.omega {
    width: 100%
}

.container .one.column, .container .one.columns {
    width: 4.06666666%
}

.container .two.columns {
    width: 10.3166666%
}

.container .three.columns {
    width: 16.5666666%
}

.container .four.columns {
    width: 22.8166666%
}

.container .five.columns {
    width: 29.0666666%
}

.container .six.columns {
    width: 35.3166666%
}

.container .seven.columns {
    width: 41.5666666%
}

.container .eight.columns {
    width: 47.8166666%
}

.container .nine.columns {
    width: 54.0666666%
}

.container .ten.columns {
    width: 60.3166666%
}

.container .eleven.columns {
    width: 66.5666666%
}

.container .twelve.columns {
    width: 72.8166666%
}

.container .thirteen.columns {
    width: 79.0666666%
}

.container .fourteen.columns {
    width: 85.3166666%
}

.container .fifteen.columns {
    width: 91.5666666%
}

.container .sixteen.columns {
    width: 97.8166666%
}

.container .third.column, .container .third.columns {
    width: 31.25%
}

.container .two-thirds.column {
    width: 64.48333333%
}

.container .fifth.columns {
    width: 17.9%
}

.container .sixth.columns {
    width: 14.4%
}

.container .offset-one {
    padding-left: 6.25%
}

.container .offset-two {
    padding-left: 12.5%
}

.container .offset-three {
    padding-left: 18.75%
}

.container .offset-four {
    padding-left: 25%
}

.container .offset-five {
    padding-left: 31.25%
}

.container .offset-six {
    padding-left: 37.5%
}

.container .offset-seven {
    padding-left: 43.75%
}

.container .offset-eight {
    padding-left: 50%
}

.container .offset-nine {
    padding-left: 56.25%
}

.container .offset-ten {
    padding-left: 62.5%
}

.container .offset-eleven {
    padding-left: 68.75%
}

.container .offset-twelve {
    padding-left: 75%
}

.container .offset-thirteen {
    padding-left: 81.25%
}

.container .offset-fourteen {
    padding-left: 87.5%
}

.container .offset-fifteen {
    padding-left: 93.75%
}

.container .offset-one.right {
    padding-left: 0;
    padding-right: 6.25%
}

.container .offset-two.right {
    padding-left: 0;
    padding-right: 12.5%
}

.container .offset-three.right {
    padding-left: 0;
    padding-right: 18.75%
}

.container .offset-four.right {
    padding-left: 0;
    padding-right: 25%
}

.container .offset-five.right {
    padding-left: 0;
    padding-right: 31.25%
}

.container .offset-six.right {
    padding-left: 0;
    padding-right: 37.5%
}

.container .offset-seven.right {
    padding-left: 0;
    padding-right: 43.75%
}

.container .offset-eight.right {
    padding-left: 0;
    padding-right: 50%
}

.container .offset-nine.right {
    padding-left: 0;
    padding-right: 56.25%
}

.container .offset-ten.right {
    padding-left: 0;
    padding-right: 62.5%
}

.container .offset-eleven.right {
    padding-left: 0;
    padding-right: 68.75%
}

.container .offset-twelve.right {
    padding-left: 0;
    padding-right: 75%
}

.container .offset-thirteen.right {
    padding-left: 0;
    padding-right: 81.25%
}

.container .offset-fourteen.right {
    padding-left: 0;
    padding-right: 87.5%
}

.container .offset-fifteen.right {
    padding-left: 0;
    padding-right: 93.75%
}

.half-offset-left {
    padding-left: 3.125%
}

.half-offset-right {
    padding-right: 3.125%
}

@media only screen and (max-width:727px) {
    .container {
        position: relative;
        width: 90%;
        margin: 0 auto;
        padding: 0
    }

    .container .column, .container .columns {
        float: left;
        display: inline;
        margin-left: 1.04166666%;
        margin-right: 1.04166666%
    }

    .column.alpha, .columns.alpha {
        margin-left: 0
    }

    .column.omega, .columns.omega {
        margin-right: 0
    }

    .container .sixteen.columns.alpha.omega {
        width: 100%
    }

    .container .one.column, .container .one.columns, .container .two.columns, .container .three.columns, .container .four.columns, .container .five.columns, .container .six.columns, .container .seven.columns, .container .eight.columns, .container .nine.columns, .container .ten.columns, .container .eleven.columns, .container .twelve.columns, .container .thirteen.columns, .container .fourteen.columns, .container .fifteen.columns, .container .sixteen.columns, .container .third.column, .container .third.columns, .container .two-thirds.column, .container .fifth.columns, .container .sixth.columns {
        width: 97.8166666%
    }

    .container .column.half-fixed, .container .one.column.half-fixed, .container .one.columns.half-fixed, .container .two.columns.half-fixed, .container .three.columns.half-fixed, .container .four.columns.half-fixed, .container .five.columns.half-fixed, .container .six.columns.half-fixed, .container .seven.columns.half-fixed, .container .eight.columns.half-fixed, .container .nine.columns.half-fixed, .container .ten.columns.half-fixed, .container .eleven.columns.half-fixed, .container .twelve.columns.half-fixed, .container .thirteen.columns.half-fixed, .container .fourteen.columns.half-fixed, .container .fifteen.columns.half-fixed, .container .sixteen.columns.half-fixed, .container .third.column.half-fixed, .container .third.columns.half-fixed, .container .two-thirds.half-fixed.column, .container .fifth.columns.half-fixed, .container .sixth.columns.half-fixed {
        width: 47.90%;
        display: inline-block;
        vertical-align: top;
        float: none;
        zoom: 1
    }

    .container .offset-one, .container .offset-two, .container .offset-three, .container .offset-four, .container .offset-five, .container .offset-six, .container .offset-seven, .container .offset-eight, .container .offset-nine, .container .offset-ten, .container .offset-eleven, .container .offset-twelve, .container .offset-thirteen, .container .offset-fourteen, .container .offset-fifteen {
        padding-left: 0
    }

    .container .offset-one.right, .container .offset-two.right, .container .offset-three.right, .container .offset-four.right, .container .offset-five.right, .container .offset-six.right, .container .offset-seven.right, .container .offset-eight.right, .container .offset-nine.right, .container .offset-ten.right, .container .offset-eleven.right, .container .offset-twelve.right, .container .offset-thirteen.right, .container .offset-fourteen.right, .container .offset-fifteen.right {
        padding-right: 0
    }

    .half-offset-left {
        padding-left: 0
    }

    .half-offset-right {
        padding-right: 0
    }
}

.container {
    width: 80%;
    margin: 0 auto;
    padding: 0;
    max-width: 1500px;
    position: relative
}

.container:after {
    display: table;
    content: ""
}

.container:after {
    clear: both
}

@media only screen and (min-width:1080px) and (max-width:1480px) {
    .container {
        width: 87%
    }
}

@media only screen and (min-width:728px) and (max-width:1079px) {
    .container {
        width: 92%
    }
}

body, html {
    font-family: 'open_sans', 'Open Sans', sans-serif;
    background: #fff
}

body:lang(ja), html:lang(ja) {
    font-family: 'open_sans', 'Open Sans', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif
}

body:lang(zh-cn), html:lang(zh-cn), body:lang(zh), html:lang(zh), body:lang(sxl), html:lang(sxl) {
    font-family: 'open_sans', 'Open Sans', 'PingFang SC', "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", sans-serif
}

body:lang(zh-tw), html:lang(zh-tw) {
    font-family: 'open_sans', 'Open Sans', 'PingFang TC', 'Microsoft JhengHei', "微軟正黑體", STXihei, sans-serif
}

strong {
    font-weight: bold
}

em {
    font-style: italic
}

img {
    max-width: 100%
}

p {
    color: #485272;
    line-height: 1.6;
    font-size: 16px
}

p a {
    color: #2aabd9
}

p a:hover {
    color: #30c5fa
}

p.light {
    color: #9199c5
}

.btn {
    background: #8aac18;
    border: 1px solid #769214;
    border-radius: 4px;
    font-family: 'brandon', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    color: white;
    cursor: pointer;
    position: relative;
    padding: 10px 20px;
    transition: all .15s
}

.btn:hover {
    background: #9ac01a;
    border-color: #8aac18
}

.btn:focus {
    outline: 0
}

.btn:before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    height: 12px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 1;
    pointer-events: none
}

.btn.big {
    padding: 20px 40px;
    font-size: 22px;
    letter-spacing: 1px;
    border-radius: 5px
}

.btn.big:before {
    border-radius: 5px
}

.btn.no-border {
    border: 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25)
}

.btn.no-border:before {
    border-top-color: rgba(255, 255, 255, 0.3)
}

.light-button {
    display: inline-block;
    zoom: 1;
    font-family: 'brandon', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    border: 1px solid #7456ec;
    padding: 16px 25px;
    letter-spacing: 1px;
    color: #7456ec;
    border-radius: 3px;
    font-size: 120%;
    transition: all .15s
}

.light-button.middle {
    vertical-align: middle
}

.light-button:lang(ja) {
    font-family: 'brandon', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif
}

.light-button:lang(zh-cn), .light-button:lang(zh), .light-button:lang(sxl) {
    font-family: 'brandon', 'PingFang SC', "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", sans-serif
}

.light-button:lang(zh-tw) {
    font-family: 'brandon', 'PingFang TC', 'Microsoft JhengHei', "微軟正黑體", STXihei, sans-serif
}

.light-button:hover {
    border-color: #926cff;
    color: #926cff;
    background: white
}

.light-button:lang(zh) {
    padding: 12px 18px;
    font-size: 110%
}

.upper-right {
    position: fixed;
    top: 30px;
    right: 32px;
    width: 400px;
    text-transform: uppercase;
    z-index: 100;
    font-family: 'brandon', sans-serif;
    text-align: right
}

.upper-right:lang(ja) {
    font-family: 'brandon', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif
}

.upper-right:lang(zh-cn), .upper-right:lang(zh), .upper-right:lang(sxl) {
    font-family: 'brandon', 'PingFang SC', "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", sans-serif
}

.upper-right:lang(zh-tw) {
    font-family: 'brandon', 'PingFang TC', 'Microsoft JhengHei', "微軟正黑體", STXihei, sans-serif
}

.upper-right a, .upper-right span {
    font-weight: 600
}

@media only screen and (max-width:727px) {
    .upper-right {
        top: 15px;
        right: 16px
    }
}

.upper-right a.button {
    display: block;
    float: right;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 10px 14px;
    line-height: 1.1;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    transition: all .2s;
    background: #6148c5;
    color: #fff
}

.upper-right a.button.small {
    padding: 4px 8px;
    font-size: 11px
}

.upper-right a.button i {
    display: inline-block;
    zoom: 1;
    margin-right: 5px;
    -webkit-transform: scale(1.1, 1.2);
    transform: scale(1.1, 1.2)
}

.upper-right a.button i.middle {
    vertical-align: middle
}

.upper-right a.hidden {
    display: none
}

.locales {
    float: right;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid transparent
}

.locales * {
    transition: all .2s
}

.locales .locale-current {
    border-radius: 3px
}

.locales .locale-current .locale-flag {
    background: #6148c5
}

.locales .locale-tag.en:before {
    content: 'EN'
}

.locales .locale-tag.ja:before {
    content: '日'
}

.locales .locale-tag.nl::before {
    content: 'NL'
}

.locales .locale-tag.sv::before {
    content: 'SV'
}

.locales .locale-tag.de::before {
    content: 'DE'
}

.locales .locale-tag.it::before {
    content: 'IT'
}

.locales .locale-tag.no::before {
    content: 'NO'
}

.locales .locale-tag.ro::before {
    content: 'RO'
}

.locales .locale-tag.cs::before {
    content: 'CS'
}

.locales .locale-tag.fr:before {
    content: 'FR'
}

.locales .locale-tag.es:before {
    content: 'ES'
}

.locales .locale-tag.zh-CN:before {
    content: '简'
}

.locales .locale-tag.zh-TW:before {
    content: '繁'
}

.locales .locale-tag.pt-BR:before {
    content: 'PT'
}

.locales .locale-tag.ar:before {
    content: 'AR'
}

.locales .locale-flag {
    width: 22px;
    height: 25px;
    color: #fff;
    font-size: 13px;
    min-width: 16px;
    font-weight: 600;
    padding: 5px 6px;
    text-shadow: none;
    line-height: 25px;
    margin-left: 10px;
    border-radius: 2px;
    text-align: center;
    display: inline-block;
    background-position: -1px -4px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2)
}

.locales .locale-selection-container {
    width: 126px;
    position: absolute;
    top: 40px;
    right: 0;
    padding: 4px;
    display: none;
    border-radius: 4px;
    background: #6148c5;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2)
}

.locales a.locale-button {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    position: relative;
    outline: 0;
    border-radius: 3px;
    margin-bottom: -1px;
    color: #fff
}

.locales a.locale-button .title {
    margin-left: 6px
}

.locales a.locale-button .dropdown-tag {
    width: unset;
    margin-left: 0;
    box-shadow: none
}

.locales a.locale-button:hover {
    background: #9883ea
}

.locales.white a.locale-button:hover {
    background: rgba(0, 0, 0, 0.2)
}

.locales.white a.locale-button span, .locales.white a.locale-button .flag {
    color: white;
    border-color: white
}

.section {
    padding: 90px 0;
    position: relative;
    overflow: hidden
}

.section>div {
    position: relative
}

@media only screen and (min-width:728px) {
    .section.reduce-top-padding {
        padding-top: 70px
    }
}

.section.gray {
    background: #f7f9ff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 -1px 1px rgba(0, 0, 0, 0.1)
}

.section.gray.no-border-top {
    box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1)
}

.section.gray.no-border-bottom {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1)
}

.section.thin {
    padding: 40px 0
}

.section.thinner {
    padding: 0
}

.section h1, .section h2 {
    color: #2c3e85;
    font-size: 33px;
    margin-bottom: 20px
}

.section h3, .section h4 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #2c3e85;
    line-height: 1.2
}

.section h4 {
    font-size: 16px
}

.section .title-group {
    margin-bottom: 60px
}

@media only screen and (max-width:1480px) {
    .section {
        padding: 70px 0
    }

    .section .title-group {
        margin-bottom: 50px
    }
}

@media only screen and (max-width:727px) {
    .section {
        padding: 40px 0
    }

    .section h1, .section h2 {
        font-size: 28px
    }

    .section .title-group {
        margin-bottom: 40px
    }
}

@-webkit-keyframes fadeSpread {
    0 {
        opacity: 0
    }

    100% {
        opacity: .075
    }
}

@keyframes fadeSpread {
    0 {
        opacity: 0
    }

    100% {
        opacity: .075
    }
}

@-webkit-keyframes fadeIn {
    0 {
        -webkit-transform: scale(.97) translate(6px, 12px);
        transform: scale(.97) translate(6px, 12px);
        opacity: 0
    }

    100% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        opacity: 1
    }
}

@keyframes fadeIn {
    0 {
        -webkit-transform: scale(.97) translate(6px, 12px);
        transform: scale(.97) translate(6px, 12px);
        opacity: 0
    }

    100% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        opacity: 1
    }
}

.section.purple {
    background: #2d8fdd;
    position: relative;
    overflow: hidden
}

.section.purple.top {
    min-height: calc(80vh - 120px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center
}

.section.purple .hero-parallax {
    position: relative
}

.section.purple .hero {
    position: absolute;
    top: 75px;
    right: -20px;
    text-align: left;
    -webkit-transform: skew(15deg, -3deg);
    transform: skew(15deg, -3deg);
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
    -webkit-transform-origin: 100% 30%;
    transform-origin: 100% 30%
}

@media only screen and (min-width:1261px) and (max-width:1560px) {
    .section.purple .hero {
        -webkit-transform: skew(15deg, -3deg) scale(.85);
        transform: skew(15deg, -3deg) scale(.85);
        top: 65px;
        right: -60px;
        -webkit-transform-origin: 100% 15%;
        transform-origin: 100% 15%
    }
}

@media only screen and (min-width:1024px) and (max-width:1260px) {
    .section.purple .hero {
        -webkit-transform: skew(15deg, -3deg) scale(.7);
        transform: skew(15deg, -3deg) scale(.7);
        top: 55px;
        right: -120px;
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
}

@media only screen and (max-width:1023px) {
    .section.purple .hero {
        display: none
    }
}

.section.purple .hero .hero-space {
    display: inline-block;
    width: 64px
}

.section.purple .hero .hero-img {
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    -webkit-animation: fadeIn 2s forwards;
    animation: fadeIn 2s forwards
}

.section.purple .hero .hero-img:nth-child(1) {
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.section.purple .hero .hero-img:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.section.purple .hero .hero-img:nth-child(3) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.section.purple .hero .hero-img:nth-child(4) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.section.purple .hero .hero-img:nth-child(5) {
    -webkit-animation-delay: 4s;
    animation-delay: 4s
}

.section.purple .hero .hero-img:nth-child(6) {
    -webkit-animation-delay: 5s;
    animation-delay: 5s
}

.section.purple .hero .hero-img.odd {
    top: 15px
}

.section.purple .logo {
    margin-bottom: 45px;
    position: relative;
    z-index: 1
}

.section.purple .logo img {
    display: inline-block
}

.section.purple h1, .section.purple h2, .section.purple h3, .section.purple a {
    color: white
}

.section.purple h1, .section.purple h2 {
    font-size: 58px;
    margin-bottom: 30px;
    font-weight: 300;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    word-spacing: 8px;
    letter-spacing: -0.5px
}

.section.purple h1 {
    text-align: center;
}

.section.purple h2 {
    font-size: 42px
}

.section.purple .tagline {
    color: white;
    font-size: 20px;
    line-height: 1.7;
    margin-bottom: 60px;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.section.purple .spread {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-attachment: fixed;
    opacity: .075;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    transition-timing-function: ease-in;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeSpread;
    animation-name: fadeSpread
}

.section.purple .start-sign-up {
    margin-bottom: 60px;
    padding: 20px 60px;
    font-weight: bold
}

.section.purple .arrow {
    margin: 0 auto;
    -webkit-transform: scaleY(.66) translate(-50%, 0);
    transform: scaleY(.66) translate(-50%, 0);
    position: absolute;
    bottom: 18px;
    left: 50%
}

.section.purple .arrow:before {
    content: ' ';
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid white;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.section.purple .container {
    position: relative;
    z-index: 10
}

@media only screen and (max-width:1480px) {
    .section.purple h1 {
        font-size: 50px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:727px) {
    .section.purple {
        padding-top: 60px
    }

    .section.purple .logo {
        margin-bottom: 35px
    }

    .section.purple h1 {
        font-size: 40px;
        margin-bottom: 20px
    }

    .section.purple .tagline {
        margin-bottom: 40px;
        line-height: 1.5;
        font-size: 125%
    }

    .section.purple .learn-more {
        margin-top: 0;
        margin-bottom: 25px
    }
}

.sep-dual {
    position: absolute !important;
    left: 50%;
    top: 100%
}

.sep-dual:before, .sep-dual:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -250px;
    left: -1000px;
    z-index: 2;
    background: white;
    width: 2000px;
    height: 250px;
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg)
}

.sep-dual:after {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg)
}

.sep-dual.sep-gray:after, .sep-dual.sep-gray:before {
    background: #f7f9ff
}

.sep-dual.sep-inset:after, .sep-dual.sep-inset:before {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1)
}

.row {
    margin-bottom: 40px
}

.profile-row {
    position: relative;
}

.profile-row:after {
    display: table;
    content: ""
}

.profile-row:after {
    clear: both
}

.profile-row ul {
    list-style-type: square;
    margin: 20px 0 0 30px;
}

.profile-row .profile img {
    display: inline-block;
    width: 100px;
    border-radius: 100px;
    margin-bottom: 10px
}

.profile-row .profile h4 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
    color: #2c3e85;
}

.profile-row .profile p.title {
    color: #4b9ee3;
    font-weight: 600;
    margin-bottom: 5px
}

@media only screen and (min-width:728px) {
    .profile-row {
        display: -webkit-flex;
        display: flex;
        width: 100%
    }

    .profile-row.right {
        -webkit-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .profile-row.right .profile {
        margin-left: auto
    }
}

@media only screen and (max-width:727px) {
    .profile-row {
        margin-bottom: 30px
    }

    .profile-row .profile {
        margin-bottom: 20px
    }

    .profile-row .profile .info, .profile-row .profile img {
        display: inline-block;
        zoom: 1;
        vertical-align: middle
    }

    .profile-row .profile .info.middle, .profile-row .profile img.middle {
        vertical-align: middle
    }

    .profile-row .profile img {
        width: 70px;
        margin: 0 10px 10px -10px
    }

    .profile-row .profile p.title {
        margin-bottom: 10px
    }

    .profile-row .profile p.quote {
        font-size: 14px
    }
}

.features .features-list .columns {
    position: relative;
    margin-top: 60px
}

.features.purple p {
    color: white;
}

.features .features-list .columns .description {
    float: left;
    margin: 0 20px;
    position: relative;
}

@media only screen and (max-width:727px) {
    .features .features-list .columns {
        margin-top: 40px;
    }

    .features .features-list .columns .description {
        margin: 0;
    }
}

.testimonials {
    overflow: hidden
}

@media only screen and (max-width:727px) {
    .testimonials .slider-container {
        padding-left: 5px !important;
        margin-left: -5px !important
    }
}

.testimonials .slider-container {
    padding: 10px 0;
    width: 9000px
}

.testimonials .slider-container, .testimonials .slider-item {
    transition: all .3s cubic-bezier(.7, 0, .3, 1)
}

.testimonials .two.columns {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    left: 0
}

.testimonials .two.columns:last-child {
    left: auto;
    right: 0
}

.testimonials .two.columns .previous-btn, .testimonials .two.columns .next-btn {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

.testimonials .two.columns .previous-btn img, .testimonials .two.columns .next-btn img {
    height: 80px;
    self-align: center
}

.testimonials .mobile-buttons {
    display: none
}

.testimonials .mobile-buttons a {
    cursor: pointer
}

.testimonials .quote-box {
    padding: 20px 25px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(0, 50, 100, 0.15);
    position: relative
}

.testimonials .quote-box.arrow:before, .testimonials .quote-box.arrow:after {
    content: ' ';
    border: 10px solid transparent;
    border-right: 10px solid #fff;
    border-left: 0;
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -10px;
    z-index: 2
}

.testimonials .quote-box.arrow:before {
    z-index: 1;
    left: -11px;
    border-right-color: #cde
}

.testimonials .testimonial {
    display: table;
    width: 100%;
    margin: 40px 0
}

@media only screen and (min-width:1080px) {
    .testimonials .testimonial:nth-child(2n) {
        margin-left: 60px
    }

    .testimonials .testimonial:nth-child(2n+1) {
        margin-left: -50px
    }
}

.testimonials .testimonial .quote, .testimonials .testimonial .info {
    display: table-cell;
    vertical-align: middle
}

.testimonials .testimonial .info {
    color: #485272;
    text-align: center;
    min-width: 140px;
    line-height: 1.3;
    min-height: 98px
}

.testimonials .testimonial .info strong {
    text-transform: uppercase
}

.testimonials .testimonial .info img {
    display: block;
    margin: 0 auto;
    border-radius: 100px;
    width: 90px;
    margin-bottom: 8px
}

.testimonials .testimonial .quote {
    text-align: left;
    padding: 10px 0 10px 30px
}

.testimonials .testimonial .quote p {
    margin-bottom: 15px;
    line-height: 1.6
}

.testimonials .testimonial .quote strong {
    color: #3d4561
}

.testimonials .testimonial .quote p:first-child {
    margin-top: 0
}

.testimonials .testimonial .quote p:last-child {
    margin-bottom: 0
}

.testimonials .twitter-feed {
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 5px;
    padding: 10px 15px
}

@media only screen and (max-width:767px) {
    .testimonials .two.columns {
        display: none
    }

    .testimonials .mobile-buttons {
        display: block;
        margin-top: 15px;
        text-transform: uppercase
    }

    .testimonials .testimonial {
        display: block;
        margin: 25px 0
    }

    .testimonials .testimonial .quote, .testimonials .testimonial .info {
        display: block
    }

    .testimonials .testimonial .quote {
        padding-left: 0
    }

    .testimonials .testimonial .quote .quote-box:before, .testimonials .testimonial .quote .quote-box:after {
        display: none
    }

    .testimonials .twitter-feed iframe {
        max-height: 500px
    }
}

.support {
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    text-shadow: 0 1px 5px white, 0 1px 20px white
}

@media only screen and (max-width:1480px) {
    .support .support-title {
        display: inline-block;
        zoom: 1;
        background: #fff;
        padding: 80px 35px;
        margin: -80px auto;
        border-radius: 5px
    }

    .support .support-title.middle {
        vertical-align: middle
    }
}

@media only screen and (max-width:1079px) {
    .support {
        background-size: cover;
        background-position: center
    }
}

@media only screen and (max-width:727px) {
    .support {
        background: none !important
    }
}

.footer {
    padding-top: 120px
}

.footer .footer-links {
    margin-top: 80px;
    margin-bottom: 40px
}

.footer .footer-links a {
    display: inline-block;
    zoom: 1;
    margin-bottom: 10px;
    color: #c8bbee;
    padding: 5px 10px
}

.footer .footer-links a.middle {
    vertical-align: middle
}

.footer .footer-links a:hover {
    color: white;
    opacity: .9
}

@media only screen and (max-width:727px) {
    .footer .footer-links {
        margin: 40px 0 20px 0
    }
}

.footer .beian-info p, .footer .beian-info a {
    color: #c8bbee;
    font-size: 12px
}

.footer .s-dash-footer {
    max-width: 1080px;
    margin: 0 auto;
    position: relative
}

.footer .s-dash-footer .footer-column .open-support-popup {
    display: none
}

.footer .s-dash-footer .footer-column .open-support-popup+br {
    display: none
}

.footer .s-dash-footer:after {
    display: table;
    content: ""
}

.footer .s-dash-footer:after {
    clear: both
}

body.shaded {
    background: #8d8f99
}

body.shaded .sections, body.shaded .upper-right {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
